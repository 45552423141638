import React, { useState } from "react"
import WindowedSelect from "react-windowed-select"
import styled from "styled-components"
import { Section, SectionTitle, SectionShadowText } from "../../components/Section"
import ProductCardList from "../../components/ProductCardList"
import NoResult from "../../components/NoResult"
import {CardInfo, CardInfoBody, FilterGrid, FilterGridItem, FilterLabel, FilterGridOr, OrSep} from "../../components/FilterComponent"

const CustomSelect = styled.div`
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  .css-yk16xz-control,
  .css-1pahdxg-control {
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    &:hover {
      border-color: none;
      border-bottom-color: #020304;
      box-shadow: none;
    }
    font-size: 16px;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-1uccc91-singleValue {
    padding-bottom: 0;
  }
  .css-1wa3eu0-placeholder {
    padding-bottom: 0px;
    color: #020304;
  }
  .css-1hwfws3 {
    padding: 10px 0;
  }
  .css-1g6gooi input{
    width: 0px !important;
  }
`

const CategoryProductSection = props => {
  const {
    sectionShadow,
    sectionTitle,
    data,
    category,
    buildingType,
    buildingApplication,
    openQuoteModal,
  } = props
  const [currentRoofStyle, setRoofStyle] = useState("All")
  const [currentBuildingType, setCurrentBuildingType] = useState("All")
  const [currentProductApp, setProductApp] = useState("All")
  const [currentPriceRange, setPriceRange] = useState("All")


  const onSetProductApp = (e) => {
    setProductApp(e.value);
    setCurrentBuildingType("All");
  }

  const onSetCurrentBuildingType = (e) => {
    setCurrentBuildingType(e.target.value);
    setProductApp("All")
  }
  let options = [{label: "All Building Application", value: "All"}];

  for (let i = 0; i < buildingApplication.length; i += 1) {
    options.push({
      label: buildingApplication[i],
      value: buildingApplication[i]
    });
  }
  const isSSR = typeof window === "undefined"

  return (
    <Section pt="90px" pb="30px" xpt="60px" xpb="0" bgColor="#F7F7F7">
      <div className="container">
        <SectionShadowText>{sectionShadow}</SectionShadowText>
        <SectionTitle>{sectionTitle}</SectionTitle>
      </div>      
      {/* commented as discussed by Ashish Sir */}
      {/* <ListBadgeWrap>
        <div className="container">
          <ListBadge>
            <div
              className={
                currentRoofStyle === "All"
                  ? "badge-white active"
                  : "badge-white"
              }
              onClick={() => tabClick("All")}
              aria-hidden="true"
            >
              <Badge badgeText="All" />
            </div>
            {allRoofStyles.map(item => (
              <div
                className={
                  currentRoofStyle === item.node.name
                    ? "badge-white active"
                    : "badge-white"
                }
                onClick={() => tabClick(item.node.name)}
                key={v4()}
                aria-hidden="true"
              >
                <Badge badgeText={item.node.name} />
              </div>
            ))}
          </ListBadge>
        </div>
      </ListBadgeWrap> */}
      <CardInfo>
        <CardInfoBody>
          <div className="container">
            <FilterGrid className="filter-grid">
              <FilterGridItem>
                <FilterLabel className="filter-label">Filters By : </FilterLabel>
              </FilterGridItem>
              <FilterGridItem>
                <div className="control-label-static">Building Type</div>
                <div className="form-group-bordered">
                  <select
                    id="building_type"
                    className="form-control"
                    onChange={e => onSetCurrentBuildingType(e)}
                    value={currentBuildingType}
                    aria-label="building type"
                  >
                    <option value="All">All Building Type</option>
                    {buildingType.map(item => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </FilterGridItem>
              <FilterGridOr>
                <OrSep>OR</OrSep>
              </FilterGridOr>
              <FilterGridItem>
              <div className="control-label-static">Building Application</div>   
                <CustomSelect className="form-group-bordered">
                  {!isSSR && <WindowedSelect placeholder="All Building Application" options={options} onChange={event => onSetProductApp(event)} />}
                </CustomSelect>
              </FilterGridItem>
              <FilterGridItem>
                <div className="control-label-static">Price Range</div>  
                <div className="form-group-bordered">
                  <select
                    id="price_range"
                    className="form-control"
                    onChange={e => setPriceRange(e.target.value)}
                    aria-label="price range"
                  >
                    <option value="All">All Price Range</option>
                    <option value="0-2000">$0-$2000</option>
                    <option value="2000-5000">$2000-$5000</option>
                    <option value="5000-20000">$5000-$20000</option>
                    <option value="20000">$20000 Above</option>
                  </select>
                </div>
              </FilterGridItem>
            </FilterGrid>
          </div>
        </CardInfoBody>
      </CardInfo>
      <div className="container">
        <div style={{display:'none'}} ><NoResult /></div>             
        <ProductCardList
          data={data}
          category={category}
          buildingType={currentBuildingType}
          buildingApp={currentProductApp}
          price={currentPriceRange}
          roofStyle={currentRoofStyle}
          openQuoteModal={openQuoteModal}
        />
      </div>
    </Section>
  )
}

export default CategoryProductSection
