import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import RightLineArrowIcon from "../Icons/RightLineArrowIcon"
import { Section } from "../Section"
import PrimaryButton from "../Button/PrimaryButton"
import BreakpointUp from "../Media/BreakpointUp"

const BackgroundImage = styled.div`
  position: relative;
  &:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    
    left:0;
    width: 100%;
    height: 50%;
    

    background: -moz-linear-gradient(top,  rgba(30,87,153,1) 0%, rgba(95,154,207,0.07) 100%);
    background: -webkit-linear-gradient(top,  rgba(30,87,153,1) 0%,rgba(95,154,207,0.07) 100%);
    background: linear-gradient(to bottom,  rgba(30,87,153,1) 0%,rgba(95,154,207,0.07) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#125f9acf',GradientType=0 );


  ${BreakpointUp.lg`	
    bottom: 0;
    left:auto;
    width: 50%;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(125, 185, 232, 0) 0%,
      rgba(95, 154, 207, 0.7) 32%,
      rgba(30, 87, 153, 1) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(125, 185, 232, 0) 0%,
      rgba(95, 154, 207, 0.7) 32%,
      rgba(30, 87, 153, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(125, 185, 232, 0) 0%,
      rgba(95, 154, 207, 0.7) 32%,
      rgba(30, 87, 153, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#1e5799',GradientType=1 );
    
  `}

  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  .container {
    padding: 0;
    ${BreakpointUp.xl` 
      max-width: 1920px;
    `}
  }
`
const TopHeaderWrap = styled.div`
  background-color:#fff;
  padding:30px 0;
  ${BreakpointUp.lg` 
    background-color:transparent;
    padding:0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    align-items: flex-start; 
    top:215px;
    transform: none;
  `}
`
const TopHeaderSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 -15px;
  ${BreakpointUp.lg`
    color: #fff;
    text-align: left;
  `}
  ${BreakpointUp.xl`
    margin:0 -30px;
  `}
`
const TopHeaderLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  ${BreakpointUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.xl`
    padding:0 30px;
  `}
`
const TopHeaderRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;  
  ${BreakpointUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.xl`
    padding:0 30px;
  `}
`

const TopHeaderTitle = styled.h1`
  font-size: 34px;
  line-height: 38px;
  margin: 0 0 10px;
  ${BreakpointUp.sm`
    margin: 0 0 20px;
    `}
  ${BreakpointUp.md`
    font-size: 58px;
    line-height: 68px;
    `}
  ${BreakpointUp.lg`
   color: #fff;
    font-size: 62px;
    line-height: 72px;
    margin: 0 0 40px;
  `}
`

const TopHeaderText = styled.div`
  p {
    margin-bottom: 0;
    + p {
      margin-top: 20px;
    }
  }
`
const TopHeaderLink = styled.div`
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding:30px 10px;
  margin-bottom:15px;
  ${BreakpointUp.md`
    flex-direction: row;
    padding:0;
    margin-bottom:0;
  `}
  ${BreakpointUp.lg`
    position: absolute;
  `}
  > span {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color:#fff;
    padding:0 0 10px;
    ${BreakpointUp.md`
      padding: 10px;
    `}
  }
  .btn {
    flex-direction: row-reverse;
    border-radius: 0;
    padding-left: 15px;
    padding-right: 30px;
    padding: 10px 18px;
    &:before {
      border-radius: 0px;
    }
    ${BreakpointUp.xl`
      font-size: 16px;
    `}
    .icon {
      margin-right: 10px;
      + .text {
        margin-left: 0;
        margin-right: 10px;
        white-space: nowrap;
      }
    }
    &:hover {
      border-color: #fff;
    }
  }
`
const Hero = props => {
  const { title, description, image, openQuoteModal } = props
  return (
    <Section pt="0" pb="0" xpt="30px" xpb="0" bgColor="#fff">      
      <BackgroundImage>
        <GatsbyImage image={image.gatsbyImageData} alt={title} />
      </BackgroundImage>
      <TopHeaderWrap>
        <div className="container">
          <TopHeaderSection>
            <TopHeaderLeft>
              <TopHeaderTitle>{title}</TopHeaderTitle>
              <TopHeaderLink>
                <span>Get Started Today With Your Free Quote..</span>
                <button
                  type="button"
                  aria-label="Request A Quote"
                  onClick={openQuoteModal}
                >
                  <PrimaryButton
                    icon={<RightLineArrowIcon />}
                    text="Request A Quote"
                  />
                </button>
              </TopHeaderLink>
            </TopHeaderLeft>
            <TopHeaderRight>
              <TopHeaderText>
                <div
                  dangerouslySetInnerHTML={{
                    __html: description.childMarkdownRemark.html,
                  }}
                />
              </TopHeaderText>
            </TopHeaderRight>
          </TopHeaderSection>
        </div>
      </TopHeaderWrap>
    </Section>
  )
}

export default Hero
