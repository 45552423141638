import React from 'react'
import styled from "styled-components"
import RequestForm from "../RequestForm"
import BreakpointUp from "../Media/BreakpointUp"

const CardStatic = styled.div`    
    background-color:#0B619B;
    margin-bottom:30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    @media (min-width: 992px) {
        box-shadow: 0 20px 50px rgba(0,0,0,0.3);
    }
`
const CardStaticTitle = styled.p`
    font-family: 'Heebo', sans-serif;
    font-weight: 700;
    text-align:center;
    margin:0 auto 30px auto;
    font-size: 20px;
    line-height: 30px;
    color:#fff;
    @media (min-width: 768px) {
        font-size: 24px;
        line-height: 34px;
    }
    @media (min-width: 992px) {
        font-size: 28px;
        line-height: 38px;
    }
`
const CardStaticBody= styled.div` 
    width:100%;
    padding:30px 15px;
    ${BreakpointUp.sm`	
        padding:30px;
    `} 
    ${BreakpointUp.lg`	
        padding:50px 30px;
    `} 
    ${BreakpointUp.xl`	
        padding:50px;
    `} 
    .form-control {
        color: #fff !important;
        border-bottom: 1px solid rgba(255,255,255,0.5) !important;
        &::placeholder {
            color: rgba(255,255,255,0.5);
        }
        &:focus, &:active{
            border-bottom-color: #fff;
            color: #fff;
            &::placeholder {
                color: #fff;
            }
        }        
        &:focus + .control-label{
            color:rgba(255,255,255,0.5);
        }      
        &:valid + .control-label {        
            color:rgba(255,255,255,0.5);
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active
    {
    -webkit-box-shadow: 0 0 0 30px #0B619B inset !important;
    -webkit-text-fill-color: #fff;
    }
    select.form-control option { color: #000; }    
    .control-label{
        color:rgba(255,255,255,0.5);
        font-weight: 500;
        &:focus, &:active{
            color:rgba(255,255,255,0.5);
        }
    }
    .form-action{
        text-align: center;
        ${BreakpointUp.lg`	
            margin-bottom: 0;
            position: absolute;
            left: 0;
            right: 0;
            top: calc(100% + 15px);
        `}
        .btn{
            &:hover{
                border-color:#fff;
            }
        }
    }



    .grid{
        display:flex;
        flex-wrap:wrap;
        flex-direction: column;
        ${BreakpointUp.lg`
            margin: 0 auto;
            width: 100%;
            /* max-width: 280px; */
        `}
        .grid-md-6 {            
            position: relative;
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .css-1wa3eu0-placeholder {
        color: rgba(255,255,255,0.5) !important;
    }
    .css-1uccc91-singleValue, .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer{
        color:#fff !important;
    }
    .css-yk16xz-control, .css-1pahdxg-control{
        border-bottom: 1px solid rgba(255,255,255,0.5) !important;
        &:focus, &:active{
            border-bottom-color: #fff !important;
        }
    }
`

const CustomQuote = ({ location }) => {    
    return(
        <CardStatic className='card card-static'>                        
            <CardStaticBody>
                <CardStaticTitle className='card-title'>Get Your Custom Quote </CardStaticTitle>                    
                <RequestForm location={location} />
            </CardStaticBody>
        </CardStatic>
    )
}

export default CustomQuote