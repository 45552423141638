import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Nodata, NodataFigure, NodataContent } from "../../components/Section"
import PrimaryButton from "../../components/Button/PrimaryButton"
import RightLineArrowIcon from "../../components/Icons/RightLineArrowIcon"


const NoResult = () => {
  return (
    <Nodata className="no-result">
        <NodataFigure className="nodata-figure"><StaticImage src="../../images/search-not-found.svg" alt="No Product Found" /></NodataFigure>
        <NodataContent className="nodata-content" maxWidth="660px">
            <strong className="h5">No Product Found</strong>
            <p>Didn't find what you are looking for, try our Building Designer to design a building for your needs here</p>
            <a href="https://coasttocoastcarports.sensei3d.com" target="_blank" rel="noreferrer" aria-label="Design Your Building" className="btn-sm"><PrimaryButton icon={<RightLineArrowIcon />} text="Design Your Building" /></a>
        </NodataContent>
    </Nodata>
  )
}

export default NoResult
