import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import RightLineArrowIcon from "../Icons/RightLineArrowIcon"
import PrimaryLinkButton from "../Button/PrimaryLinkButton"
import BreakpointUp from "../Media/BreakpointUp"

const BuildingStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  margin: 0 -15px 80px;
  ${BreakpointUp.lg`				
        flex-direction: row-reverse;	
    `}
`
const BuildingStyleFigure = styled.div`
  width: 100%;
  margin-bottom: 0;
  position: relative;
  padding: 0 15px;
  &:before {
    content: attr(data-shadowtext);
    position: absolute;
    bottom: 100%;
    color: rgba(0, 0, 0, 0.1);
    font-weight: 900;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: none;
    text-align: center;
    ${BreakpointUp.md`
			display:block;
			font-size: 34px;
			line-height: 24px;
		`}
    ${BreakpointUp.xl`
			font-size: 38px;
			line-height: 28px;
		`}
  }
  & .gatsby-image-wrapper {
    height: 100%;
    max-height: 300px;
  }
  ${BreakpointUp.lg`		
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
        
    `}
`
const BuildingStyleBody = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 15px;
  ${BreakpointUp.lg`		
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
	`}
`

const BuildingStyleHeading = styled.div`
  padding: 0;
`
const BuildingStyleTitle = styled.h3`
  margin: 0 0 5px;
  font-weight: 900;
  a {
    color: #000;
    &:hover,
    &:focus {
      color: #0b619b;
    }
  }
`
const BuildingStyleDesc = styled.div`
  p {
    ${BreakpointUp.lg`		
            font-size:18px;
            line-height:34px;
        `}
  }
`
const BtnToolbar = styled.div`
  .btn {
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    .icon {
      svg {
        width: 16px;
      }
    }
  }
`

const BuildingStyleCard = props => {
  const { data } = props
  return (
    <BuildingStyle className="card">
      <BuildingStyleFigure className="card-img" data-shadowtext={data.label}>
        <GatsbyImage
          image={data.image.gatsbyImageData}
          alt={data.title}
        />
      </BuildingStyleFigure>
      <BuildingStyleBody className="card-body">
        <BuildingStyleHeading>
          <BuildingStyleTitle className="card-title">
            {data.title}
          </BuildingStyleTitle>
        </BuildingStyleHeading>
        <BuildingStyleDesc>
          <div
            dangerouslySetInnerHTML={{
              __html: data.description.childMarkdownRemark.html,
            }}
          />
        </BuildingStyleDesc>
        {data.buttonLink ? (
          <BtnToolbar>
            <Link to={data.buttonLink}>
              <PrimaryLinkButton
                icon={<RightLineArrowIcon />}
                text={data.buttonText}
              />
            </Link>
          </BtnToolbar>
        ) : (
          <></>
        )}
      </BuildingStyleBody>
    </BuildingStyle>
  )
}

export default BuildingStyleCard
