import styled from "styled-components"
import BreakpointUp from "../Media/BreakpointUp"

export const CardInfo = styled.div`
  position:relative;  
  display: flex;
  flex-wrap: wrap;    
`
export const CardInfoBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom:1px solid #EBEDF3;
  background-color: #fff;
  width: 100%;
  margin-bottom: 50px;  
  padding: 60px 0 30px 0;
  ${BreakpointUp.xxl`
    padding: 90px 0 60px 0;
  `}
  form{
    width:100%;
  }
`
export const FilterGrid = styled.div`	
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;      
  ${BreakpointUp.lg`
    margin-right: -10px;
    margin-left: -10px;
  `}
  ${BreakpointUp.xl`
    margin-right: -20px;
    margin-left: -20px;
  `}
  ${BreakpointUp.xxl`
    margin-right: -30px;
    margin-left: -30px;
  `}
  .form-group-bordered{
    box-shadow:0 10px 25px rgba(0,0,0,0.16);
    margin-bottom:20px;
    padding: 15px 12px;
    ${BreakpointUp.sm`
        margin-bottom:30px;
    `}
    ${BreakpointUp.lg`
        padding: 15px 20px;
    `}
    .form-control{
      border:none;
      padding:0;
    }
  }  
  .control-label-static{
    margin-bottom:5px;
  }
`

export const FilterGridItem = styled.div`	
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`
    padding-right: 10px;
    padding-left: 10px;
  `}
  ${BreakpointUp.xl`
    padding-right: 20px;
    padding-left: 20px;
  `}
  ${BreakpointUp.xxl`
    padding-right: 30px;
    padding-left: 30px;
  `}
  &:first-child{
    max-width: 100%;
    flex: 0 0 100%;
    text-align:center;
    ${BreakpointUp.lg`
      width: auto;
      max-width: 100%;
      flex: 0 0 auto;
    `}
  }
  ${BreakpointUp.sm`
    max-width: calc(33.3333% - 30px);
    flex: 0 0 calc(33.3333% - 30px);
  `}
  ${BreakpointUp.lg`				                            
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  `}
`
export const FilterGridOr = styled.div`	
    position: relative;
    padding-right: 0;
    padding-left: 0;
    width: auto;
    max-width: 100%;
    flex: 0 0 auto;
`
export const FilterLabel = styled.div`	
  padding: 12px 30px;
  border-radius: 30px;
  border: 1px solid #ccc;
  position: relative;
  color: #000;
  font-family: 'Heebo',sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  margin:0 0 15px;
  display: block;  
  margin:0 0 20px;
  
  ${BreakpointUp.sm`
    display: inline-block;    
  `}
  ${BreakpointUp.lg`
    margin:30px 0;
  `}
  &:after{
    content:'';
    position: absolute;
    top: 50%;
    right: -15px;
    width: 30px;
    height: 2px;
    background-color: #000;
    box-shadow:0px 0px 0px 2px #fff;
  }
`
export const OrSep = styled.div`	
    color: ${props => props.color};
    font-weight: 700;
    margin: 15px 0;
    line-height: 24px;
    ${BreakpointUp.sm`
        margin:0;
    `}
`
OrSep.defaultProps = {
  color:"#000",
}