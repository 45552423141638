import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { v4 } from "uuid"
import { GatsbyImage } from "gatsby-plugin-image"
import RightLineArrowIcon from "../../components/Icons/RightLineArrowIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"

const Section = styled.div`
  margin: 0;
  counter-reset: section;
`
const SectionItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: calc((100% - 1350px) / 2);
  align-items: center;
  position: relative;
  padding: 30px 0;
  ${BreakpointUp.md`
    padding: 50px 0;
    `}
  ${BreakpointUp.lg`
        padding: 90px 0;
        overflow: hidden; 
    `}
    &:before {
    ${BreakpointUp.lg`
            display: block;
            content: '';
            position: absolute;
            top: 50%;       
            transform: translateY(-50%);
            border-radius: 50%;
            border: 140px solid rgba(221,228,240,0.5);
            right: -340px;        
            z-index: 0;
            width: 680px;
            height: 680px;
            overflow: hidden;
        `}
  }

  ${BreakpointUp.lg`        
        &:nth-child(even){
            padding-right: 0;
            flex-direction: row-reverse;
            &:before{
                left: -390px;
                right:auto;
            }
            & .thumbnail{
                &:before{
                    left:auto;
                    right: -50%;
                }
                &:after{
                    left:auto;
                    right: -100px;
                }
            }
            .section-left{
                padding:0 125px 0 25px;
            }
            .section-right{
                padding:0 25px 0 125px;
            }
        }
    `}
`

const SectionLeft = styled.div`
  width: 100%;
  position: relative;
  padding: 0 15px;
  ${BreakpointUp.sm`  
        padding:0 25px 0 125px;
    `}
  ${BreakpointUp.xl`  
        flex: 0 0 50%;
        max-width: 50%;
    `}
`

const SectionRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.sm`  
        padding:0 125px 0 25px; 
    `}
  ${BreakpointUp.xl`  
        flex: 0 0 50%;
        max-width: 50%;
    `}
`
const Thumbnail = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &:before {
    content: "";
    left: -50%;
    width: 50%;
    background-color: #0b619b;
    position: absolute;
    height: 50%;
    top: 50%;
    transform: translateY(-50%);
  }
  &:after {
    counter-increment: section;
    content: counter(section) "";
    display: block;
    position: absolute;
    left: -100px;
    top: 50%;
    color: #fff;
    font-weight: 700;
    font-size: 90px;
    line-height: 90px;
    transform: translateY(-50%);
  }
`
const ThumbFigure = styled.figure`
  width: 100%;
  margin-bottom: 0;
  position: relative;
  &:before {
    content: attr(data-shadowtext);
    position: absolute;
    bottom: 100%;
    color: rgba(0, 0, 0, 0.1);
    font-weight: 900;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: none;
    text-align: center;
    @media (min-width: 768px) {
      display: block;
      font-size: 48px;
      line-height: 38px;
    }
    @media (min-width: 992px) {
      font-size: 65px;
      line-height: 45px;
    }
  }
  & .gatsby-image-wrapper {
    height: 100%;
  }
`
// const ThumbLink = styled.div`
// 	background-color:#000;
// 	color:#fff;
// 	line-height:20px;
//     font-weight:700;
// 	position:absolute;
//     top:0;
//     left:50%;
//     transform:translateX(-50%);
//     z-index:1;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     width: 100%;
//     font-size:16px;
//     max-width: 280px;
//     > .text{
//         padding:0 12px;
//     }
//     .btn{
//         border-radius: 0;
//         padding: 0;
//         height: 44px;
//         width: 44px;
//         &:before {
//             border-radius: 0;
//         }
//         .text{
//             display:none;
//         }
//     }
// `
const ThumbnailBody = styled.div`
  h3 {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 30px;
    ${BreakpointUp.lg`
            font-size: 18px;
            line-height: 32px;
		`}
  }
`
const BtnToolbar = styled.div`
  a {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 700;
    position: relative;
    border: 1px solid #0b619b;
    background-color: #0b619b;
    color: #fff;
    transition: all 0.4s ease-in-out;
    transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out,
      border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    justify-content: left;
    padding: 15px 20px;
    font-size: 16px;
    ${BreakpointUp.sm`
            padding:16px 40px;
            font-size:18px;
        `}
    > .text {
      line-height: 26px;
      display: inline-flex;
      align-items: center;
      + .icon {
        margin-left: 10px;
      }
      span {
        display: none;
        ${BreakpointUp.sm`
                    display:inline-block;
                `}
      }
    }
    > .icon {
      height: 26px;
      svg {
        fill: #fff;
      }
    }
    &:hover,
    &:focus {
      background-color: #fc0002;
      border-color: #fc0002;
    }
  }
`

const ProductRoofStyle = ({ data }) => {
  return (
    <Section>
      {data.map(item => {
        return (
          <SectionItem key={v4()}>
            <SectionLeft className="section-left">
              <Thumbnail className="thumbnail">
                {/* <ThumbLink>
                    <span className="text">See {item.label} Products</span>
                    <Link to="/"><PrimaryButton icon={<ArrowDownIcon />} /></Link>
                </ThumbLink> */}
                <ThumbFigure className="thumb-img" data-shadowtext={item.label}>
                  <GatsbyImage
                    image={item.image.gatsbyImageData}
                    alt={item.title}
                  />
                </ThumbFigure>
              </Thumbnail>
            </SectionLeft>
            <SectionRight className="section-right">
              <ThumbnailBody>
                <h3>{item.title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.description.childMarkdownRemark.html,
                  }}
                />
              </ThumbnailBody>
              {item.buttonLink && (
                <BtnToolbar>
                  <Link to={item.buttonLink}>
                    <span className="text">{item.buttonText}</span>
                    <span className="icon">
                      <RightLineArrowIcon />
                    </span>
                  </Link>
                </BtnToolbar>
              )}
            </SectionRight>
          </SectionItem>
        )
      })}
    </Section>
  )
}

export default ProductRoofStyle
